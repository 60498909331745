<template>
  <v-card height="100%" width="256" class="mx-auto ">
    <v-navigation-drawer permanent color="#81e9e913">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="logo">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ empresa }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list dense>
        <div v-for="( item,index ) in itemsMenu" :key="'mn'+index">
          <v-tooltip bottom v-if="item.submenus.length == 0" color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item exact router :target="(item.to.includes('http')) ? '_blank' : '' " :href="item.to" v-bind="attrs" v-on="on" color="primary">
                <v-list-item-action class="pa-0" >
                  <v-icon class="primary--text text--darken-1">{{item.icon}}</v-icon>
                </v-list-item-action>

                <v-list-item-content class="primary--text text--darken-1">
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{item.title}}</span>
          </v-tooltip>

          <div v-else>
            <v-list-group v-model="item.active">

                <template v-slot:activator>
                  <v-tooltip right bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action class="pa-0" v-bind="attrs" v-on="on">
                          <v-icon class="primary--text text--darken-1">{{item.icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>{{item.title}}</span>
                  </v-tooltip>
                </template>

                <v-list-item
                  v-for="( option, index ) in item.submenus"
                  :key="'smn'+index"
                  router exact :to="option.to"
                >
                  <v-tooltip right bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action v-bind="attrs" v-on="on">
                        <v-icon x-small class="primary--text text--darken-1" >{{option.icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="primary--text text--darken-1" v-bind="attrs" v-on="on">
                        <v-list-item-title  v-text="option.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>{{option.title}}</span>
                  </v-tooltip>
                </v-list-item>
            </v-list-group>
          </div>
      </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  export default {
    data () {
      return {
        logo: require('@/assets/oneboxlogo.png'),
      //logo: process.env.VUE_APP_URL_YO + this.$store.state.user.Emp_Imagen,
        empresa: this.$store.state.user.Emp_Nombre,
        right: null,
        itemsMenu: [
           /*  { title: 'Inicio', icon: 'mdi-home', to: '/', submenus:[]}, */
            { title: 'Colaboradores', icon: 'mdi-account-group', to: '/#/colab', submenus:[] },

            { title: 'CRM Colaboradores', icon: 'mdi-human-greeting-proximity', to: '/#/CRMColab', submenus:[] },

            { title: 'Reportes', icon: 'mdi-format-list-checks', submenus:
              [
                { title: 'Reporte de Licencias', icon: 'mdi-circle-medium', to:'/reporteLicencias'},
                { title: 'Log de Licencias', icon: 'mdi-circle-medium', to:'/logLicencias'},
              ]
            },

            { title: 'Documentos Privados ', icon: 'mdi-folder-lock-outline', submenus:
              [
                { title: 'Subir Documentos ', icon: 'mdi-circle-medium',  to:'/documentosPrivados'}, /*  to:'/documentosPrivados' */
              ]
            },

            { title: 'Avisos de Asistencia', icon: 'mdi-account-clock-outline', submenus:
              [
                { title: 'Control de Asistencia', icon: 'mdi-circle-medium', to:'/controlAsistencia'  },
              ]
            },
            
            { title: 'Experiencia Nuevos Colaboradores', icon: 'mdi-account-star', submenus:
              [
                { title: 'Reporte de Participación', icon: 'mdi-circle-medium', to:'/participacion'},
                { title: 'Dashboard ENC', icon: 'mdi-circle-medium', to:'/dashboard'},
                { title: 'Listado de Colaboradores en Seguimiento', icon: 'mdi-circle-medium', to:'/listadoENC'},
              ]
            },

            { title: 'Campañas', icon: 'mdi-format-list-bulleted-square', submenus:
              [
                { title: 'Crear Campaña', icon: 'mdi-circle-medium', to:'/campaign'  },
                { title: 'Resultados Campaña ', icon: 'mdi-circle-medium',  to:'/resultadosCampaign' }, 
              ]
            },

            { title: 'Campañas Yomob', icon: 'mdi-format-list-checkbox', submenus:
              [
                { title: 'Crear Campaña Yomob', icon: 'mdi-circle-medium', to:'/campaignYomob'  }, /* to:'/campaignYomob' */
                { title: 'Resultados Campaña Yomob ', icon: 'mdi-circle-medium', to:'/resultadosCampaignYomob'}, 
              ]
            },

            { title: 'Encuesta Clima', icon: 'mdi-hexagon-multiple', submenus:
              [
                { title: 'Cuestionarios Disponibles', icon: 'mdi-circle-medium',  to:'/cuestionariosClima' },
                { title: 'Encuestas Disponibles', icon: 'mdi-circle-medium',  to:'/encuestasClima' },
                { title: 'Reporte de Participación', icon: 'mdi-circle-medium', to:'/participacionClima'},
                { title: 'Dashboard de Clima', icon: 'mdi-circle-medium', to:'/dashboardClima'},
                { title: 'Resultados Encuestas Clima', icon: 'mdi-circle-medium', to:'/resultados'},
              ]
            },

            { title: 'Encuesta Clima Yomob', icon: 'mdi-hexagon-multiple-outline', submenus:
              [
                { title: 'Cuestionarios Disponibles', icon: 'mdi-circle-medium',  to:'/cuestionariosClimaYomob' }, 
                { title: 'Encuestas Disponibles', icon: 'mdi-circle-medium', to:'/encuestasClimaYomob'}, 
                { title: 'Reporte de Participación Yomob', icon: 'mdi-circle-medium', to:'/participacionClimaYomob' },
                { title: 'Dashboard de Clima Yomob', icon: 'mdi-circle-medium', to:'/dashboardClimaYomob' },
                { title: 'Resultados Encuestas Clima Yomob', icon: 'mdi-circle-medium',  to:'/resultadosYomob' },
              ]
            },

            { title: 'Encuesta con IA', to: process.env.VUE_APP_ENCUESTAS+"#/auth/"+this.$store.state.user.authToken+"/"+this.$store.state.user.Usu_Id+"/Generator", icon: 'mdi-laptop', submenus: []}
           /*  { title: 'Configuración',
              icon: 'mdi-cog-outline',
              submenus:[
                { title: 'Tipos de Cuestionarios', to:'/typeQuestionnaires'},
                { title: 'Categorías de Preguntas', to:'/questionCategories'},
                { title: 'Tipos de Instalaciones', to:'/installationsTypes'},
                { title: 'Feriados', to:'/holidays'},
                { title: 'Canales', to:'/channels'},
              ]
            }, */

        ]
      }
    },
    beforeMount(){
      console.log('User ', this.$store.state.user);
    }
  }
</script>

<style >

</style>
